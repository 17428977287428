import { VAlert } from 'vuetify/lib/components/VAlert';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fluid":""}},[_c('h1',{staticClass:"page-title font-weight-light"},[_vm._v("Admin Panel")]),(_vm.user.authenticated && _vm.isAdmin(_vm.user.role))?_c('div',[_c(VTabs,{attrs:{"color":"white","background-color":"rfaccent2","fixed-tabs":"","centered":"","icons-and-text":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTab,[_vm._v(" Statistics "),_c(VIcon,[_vm._v("mdi-chart-areaspline")])],1),_c(VTab,[_vm._v(" Users "),_c(VIcon,[_vm._v("mdi-account-group")])],1),_c(VTab,[_vm._v(" Repeaters "),_c(VIcon,[_vm._v("mdi-radio-tower")])],1),_c(VTab,[_vm._v(" Comments "),_c(VIcon,[_vm._v("mdi-comment-multiple")])],1),_c(VTab,[_vm._v(" Reports "),_c(VIcon,[_vm._v("mdi-comment-multiple")])],1),_c(VTabsItems,{attrs:{"touchless":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c(VTabItem,[_c('Stats',{attrs:{"user":_vm.user}})],1),_c(VTabItem,[_c('Users',{attrs:{"user":_vm.user}})],1),_c(VTabItem,[_c('Repeaters',{attrs:{"user":_vm.user}})],1),_c(VTabItem,[_c('Comments',{attrs:{"user":_vm.user}})],1),_c(VTabItem,[_c('Reports',{attrs:{"user":_vm.user}})],1)],1)],1)],1):_c('div',[_c(VAlert,{staticClass:"mt-5",attrs:{"type":"error","prominent":""}},[_vm._v(" You must be an administrator to view this page. ")])],1)])}
var staticRenderFns = []

export { render, staticRenderFns }