<template>
    <div>

        <v-alert type="error" colored-border border="left" dense v-if="error.state">{{ error.message }}</v-alert>

        <v-text-field
            v-model="search"
            label="Search"
            prepend-inner-icon="mdi-magnify"
            solo-inverted
            hide-details
            clearable
            dense
            class="mx-5 mt-3"
        ></v-text-field>

        <v-data-table
            :headers="headers"
            :items="users"
            :loading="loading"
            :server-items-length="total"
            :search="search"
            :options.sync="options"
            :footer-props="{itemsPerPageOptions: [10, 20, 30, 40, 50]}"
            :mobile-breakpoint="0"
            sort-by="Last Login"
            sort-desc
            must-sort
            show-expand
            single-expand
            item-key="ID"
            :expanded.sync="expanded"
            @click:row="rowClick"
            class="elevation-2"
        >
            <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                    <User :authUser="user" :username="item.Username" :key="item.Username"/>

                    <!--                    <v-list two-line>-->
                    <!--                        <v-list-item>-->
                    <!--                            <v-list-item-content>-->
                    <!--                                <v-list-item-title>{{ item.Username }}</v-list-item-title>-->
                    <!--                                <v-list-item-subtitle>Username</v-list-item-subtitle>-->
                    <!--                            </v-list-item-content>-->
                    <!--                        </v-list-item>-->
                    <!--                        <v-list-item>-->
                    <!--                            <v-list-item-content>-->
                    <!--                                <v-list-item-title>{{ item.Callsign }}</v-list-item-title>-->
                    <!--                                <v-list-item-subtitle>Callsign</v-list-item-subtitle>-->
                    <!--                            </v-list-item-content>-->
                    <!--                        </v-list-item>-->
                    <!--                        <v-list-item>-->
                    <!--                            <v-list-item-content>-->
                    <!--                                <v-list-item-title>{{ item.Email }}</v-list-item-title>-->
                    <!--                                <v-list-item-subtitle>Email</v-list-item-subtitle>-->
                    <!--                            </v-list-item-content>-->
                    <!--                        </v-list-item>-->
                    <!--                    </v-list>-->
                </td>
            </template>

            <template v-slot:item.actions="{ item }">
                <v-menu bottom left>
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                            icon
                            v-bind="attrs"
                            v-on="on"
                        >
                            <v-icon>mdi-dots-vertical</v-icon>
                        </v-btn>
                    </template>

                    <v-list>
                        <v-list-item @click="openUserDialog(item)">
                            <v-list-item-icon>
                                <v-icon>mdi-account-edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Edit User</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="sendResetPassword(item.Username)">
                            <v-list-item-icon>
                                <v-icon>mdi-key-variant</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Reset Password</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="confirmBanUser(item, !item.Banned)">
                            <v-list-item-icon>
                                <v-icon>mdi-cancel</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title v-if="item.Banned">Unban User</v-list-item-title>
                            <v-list-item-title v-else>Ban User</v-list-item-title>
                        </v-list-item>
                        <v-list-item @click="confirmDeleteUser(item)">
                            <v-list-item-icon>
                                <v-icon>mdi-close</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Delete Account</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </v-menu>
            </template>

            <template v-slot:item.Username="{ item }">
                <UserAvatar :user="{username: item.Username, callsign: item.Callsign}" xsmall class="mr-1"/>
                {{ item.Username }}
            </template>

            <!--            <template v-slot:item.Role="{ item }">-->
            <!--                <v-icon v-if="item.Role === 0" color="info">mdi-account-tie</v-icon>-->
            <!--                <v-icon v-else-if="item.Role === 1">mdi-account</v-icon>-->
            <!--                <v-icon v-else color="warning">{{ item.Role }}</v-icon>-->
            <!--            </template>-->

            <template v-slot:item.Banned="{ item }">
                <v-icon v-if="item.Banned === 1" color="error">mdi-account-cancel</v-icon>
            </template>

            <template v-slot:item.Verified="{ item }">
                <v-icon v-if="item.Verified === 1" color="success">mdi-account-check</v-icon>
            </template>

            <template v-slot:item.PasswordExpired="{ item }">
                <v-icon v-if="item.PasswordExpired === 1" color="error">mdi-clock-alert-outline</v-icon>
            </template>

            <template v-slot:item.StripeCustomer="{ item }">
                <a
                    :href="`https://dashboard.stripe.com/customers/${item.StripeCustomer}`"
                    :target="`stripe_${item.StripeCustomer}`"
                >
                    {{ item.StripeCustomer }}</a>
            </template>

            <template v-slot:item.SubscriptionEndDate="{ item }">
                <span v-if="item.SubscriptionEndDate">
                    <span v-if="$vuetify.breakpoint.mdAndUp">
                        {{ $moment.unix(item.SubscriptionEndDate).format('M/D/YYYY') }}
                        <small>({{ $moment.unix(item.SubscriptionEndDate).fromNow() }})</small>
                    </span>
                    <span v-else>
                        {{ $moment.unix(item.SubscriptionEndDate).fromNow() }}
                    </span>
                </span>
            </template>

            <template v-slot:item.LastLogin="{ item }">
                <span v-if="item.LastLogin">
                    <span v-if="$vuetify.breakpoint.mdAndUp">
                        {{ $moment(item.LastLogin).format('M/D/YYYY') }}
                        <small>({{ $moment(item.LastLogin).fromNow() }})</small>
                    </span>
                    <span v-else>
                        {{ $moment(item.LastLogin).fromNow() }}
                    </span>
                </span>
            </template>
        </v-data-table>

        <v-dialog v-model="dialogs.editUser.state" fullscreend max-width="1000">
            <v-card v-if="dialogs.editUser.user">
                <v-card-title class="font-weight-light px-0 pt-0">
                    <v-toolbar dense color="rfaccent2" dark>
                        Edit User: {{ dialogs.editUser.user.Username }} ({{ dialogs.editUser.user.Callsign }})
                        <v-spacer></v-spacer>
                        <v-btn icon @click="closeUserDialog">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-toolbar>
                </v-card-title>
                <v-card-text class="pt-5">

                    <v-form>
                        <v-text-field
                            v-model="dialogs.editUser.user.ID"
                            label="ID"
                            outlined
                            disabled
                            dense
                        ></v-text-field>

                        <v-text-field
                            v-model="dialogs.editUser.user.Username"
                            :placeholder="dialogs.editUser.origUser.Username"
                            label="Username"
                            outlined
                            dense
                        ></v-text-field>

                        <v-text-field
                            v-model="dialogs.editUser.user.Email"
                            :placeholder="dialogs.editUser.origUser.Email"
                            label="Email Address"
                            outlined
                            dense
                        ></v-text-field>

                        <div class="d-flex justify-space-between">
                            <v-text-field
                                v-model="dialogs.editUser.user.Password"
                                label="Password"
                                type="password"
                                outlined
                                dense
                            ></v-text-field>
                            <v-btn color="rfaccent2" class="ml-5" @click="generatePassword">
                                <v-icon class="mr-1" small>mdi-content-copy</v-icon>
                                Generate
                            </v-btn>
                        </div>

                        <v-text-field
                            v-model="dialogs.editUser.user.Callsign"
                            :placeholder="dialogs.editUser.origUser.Callsign"
                            label="Callsign"
                            outlined
                            dense
                        ></v-text-field>

                        <v-text-field
                            v-model="dialogs.editUser.LastLoginFormatted"
                            label="Last Login"
                            prepend-inner-icon="mdi-clock-outline"
                            outlined
                            dense
                            readonly
                        ></v-text-field>

                        <v-text-field
                            v-model="dialogs.editUser.user.StripeCustomer"
                            :placeholder="dialogs.editUser.origUser.StripeCustomer"
                            label="Stripe Customer ID"
                            outlined
                            dense
                            readonly
                        ></v-text-field>

                        <v-menu
                            v-model="dialogs.editUser.menuSubscription"
                            :close-on-content-click="false"
                            offset-y
                            max-width="290"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="dialogs.editUser.user.SubscriptionEndDate"
                                    :placeholder="dialogs.editUser.origUser.SubscriptionEndDate"
                                    label="Subscription Ends"
                                    prepend-inner-icon="mdi-alarm"
                                    outlined
                                    dense
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                ></v-text-field>
                            </template>

                            <v-card>
                                <v-date-picker
                                    v-model="dialogs.editUser.user.SubscriptionEndDate"
                                    show-adjacent-months
                                ></v-date-picker>

                                <v-card-actions>
                                    <v-spacer></v-spacer>
                                    <v-btn color="rfaccent2" @click="dialogs.editUser.user.SubscriptionEndDate = ''">
                                        Clear
                                    </v-btn>
                                    <!-- <v-btn-->
                                    <!-- text-->
                                    <!-- @click="dialogs.editUser.menuSubscription = false"-->
                                    <!-- >-->
                                    <!-- Cancel-->
                                    <!-- </v-btn>-->
                                    <!-- <v-btn-->
                                    <!-- color="rfaccent2"-->
                                    <!-- @click="dialogs.editUser.menuSubscription = false"-->
                                    <!-- >-->
                                    <!-- Save-->
                                    <!-- </v-btn>-->
                                </v-card-actions>
                            </v-card>
                        </v-menu>

                        <v-select
                            v-model="dialogs.editUser.user.Role"
                            label="User Role"
                            :items="userRoles"
                            outlined
                            dense
                        ></v-select>

                        <div class="d-flex justify-space-around">
                            <div>
                                <v-switch
                                    v-model="dialogs.editUser.user.Digest"
                                    label="Repeater Digest Emails"
                                ></v-switch>

                                <v-switch
                                    v-model="dialogs.editUser.user.Requests"
                                    label="Repeater Request Emails"
                                ></v-switch>

                                <v-switch
                                    v-model="dialogs.editUser.user.Verified"
                                    label="Email Verified"
                                    color="success"
                                ></v-switch>
                            </div>
                            <div>
                                <v-switch
                                    v-model="dialogs.editUser.user.Banned"
                                    label="Banned"
                                    color="error"
                                ></v-switch>

                                <v-switch
                                    v-model="dialogs.editUser.user.PasswordExpired"
                                    label="Password Expired"
                                    color="warning"
                                ></v-switch>
                            </div>
                        </div>

                        <v-sheet light class="mb-5 elevation-5">
                            <vue-editor v-model="dialogs.editUser.user.Profile" label="Profile"></vue-editor>
                        </v-sheet>
                    </v-form>

                </v-card-text>
                <v-card-actions>
                    <v-btn @click="closeUserDialog">Cancel</v-btn>
                    <v-spacer></v-spacer>
                    <v-btn dark color="rfaccent2" @click="saveUser">
                        Save
                        <v-icon>mdi-chevron-right</v-icon>
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-snackbar
            v-model="snackbar.state"
            :color="snackbar.color"
            :timeout="snackbar.timeout"
            multi-line
            top
        >
            <v-icon v-if="snackbar.icon" class="mr-1">{{ snackbar.icon }}</v-icon>
            {{ snackbar.text }}
        </v-snackbar>
    </div>
</template>

<script>
    import axios from 'axios'
    import config from "@/config";
    import UserAvatar from "@/components/UserAvatar";
    import User from "@/components/User";
    import { VueEditor } from 'vue2-editor';
    import md5 from 'md5';

    const CancelToken = axios.CancelToken;
    let source = CancelToken.source();

    export default {
        components: {UserAvatar, User, VueEditor},

        props: ['user'],

        data: () => ({
            headers: [
                {text: 'ID', value: 'ID', width: 70},
                {text: 'Username', value: 'Username', width: 200},
                {text: 'Callsign', value: 'Callsign', width: 100},
                // {text: 'First Name', value: 'First_Name', width: 200},
                // {text: 'Last Name', value: 'Last_Name', width: 200},
                {text: 'Email', value: 'Email', width: 300},
                {text: 'Role', value: 'Role'},
                {text: 'Banned', value: 'Banned'},
                {text: 'Verified', value: 'Verified'},
                {text: 'Password Expired', value: 'PasswordExpired'},
                {text: 'Stripe Customer', value: 'StripeCustomer'},
                {text: 'Subscription Ends', value: 'SubscriptionEndDate'},
                {text: 'Last Login', value: 'LastLogin'},
                {text: '', value: 'actions', sortable: false},
            ],
            users: [],
            expanded: [],
            total: null,
            search: '',
            loading: false,
            options: {},
            userRoles: [
                {text: 'Administrator', value: 0},
                {text: 'User', value: 1},
                // {text: 'Premium Member', value: 2} //@todo the DB doesn't use this for Premium, it uses the subscription to determine
            ],
            error: {
                state: false,
                message: null
            },
            dialogs: {
                editUser: {
                    state: false,
                    user: null,
                    menuSubscription: false,
                    LastLoginFormatted: null
                }
            },
            snackbar: {
                state: false,
                color: null,
                timeout: 3000,
                text: null
            }
        }),

        watch: {
            search: _.throttle(function () {
                this.fetchUsers();
            }, 500),

            options: {
                handler() {
                    this.fetchUsers();
                },
                deep: true
            }
        },

        methods: {
            generatePassword() {
                this.dialogs.editUser.user.Password = btoa(md5(Date.now())).substring(0, 10);

                navigator.clipboard.writeText(this.dialogs.editUser.user.Password);

                this.snackbar.text = 'New password copied to clipboard!';
                this.snackbar.color = 'success';
                this.snackbar.state = true;
            },

            async fetchUsers() {
                try {
                    //cancel in-flight requests
                    if (this.loading && source) {
                        source.cancel();

                        //generate new token for future request
                        source = CancelToken.source()
                    }

                    this.loading = true;

                    let offset = ((this.options.page - 1) * this.options.itemsPerPage);
                    let query = `${config.API_LOCATION}/users?limit=${this.options.itemsPerPage}&skip=${offset}&sort=${this.options.sortBy}&descending=${this.options.sortDesc}`;
                    if (this.search) query += `&search=${this.search}`;

                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.get(query, {
                        headers: axiosHeaders,
                        cancelToken: source.token
                    });

                    this.error.state = false;
                    this.error.message = null;
                    this.loading = false;

                    this.users = this.$_.map(response.data.items, o => {
                        o.LastLogin = o['Last Login'];
                        delete o['Last Login'];
                        return o;
                    });
                    this.total = response.data.info.total;
                } catch (err) {
                    this.loading = false;

                    //ignore requests we intentionally cancelled
                    if (!axios.isCancel(err)) {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        } else {
                            console.error(err);
                            this.error.message = 'An error occurred while searching for users.';
                            this.error.state = true;
                        }
                    }
                }
            },

            rowClick(row) {
                // this.dialogs.editUser.user = row;
                // this.dialogs.editUser.state = true;

                if (this.expanded.length > 0 && this.expanded[0] === row) this.expanded = [];
                else this.expanded = [row];
            },

            async saveUser() {
                try {
                    //data massaging :(
                    const updatedUser = _.omit(this.dialogs.editUser.user, ['SubscriptionEndDate', 'LastLogin']);
                    if (updatedUser.Password && updatedUser.Password.trim() === '') delete updatedUser['Password'];

                    //format the SubscriptionEndDate from a YYYY-MM-DD to Unix timestamp
                    if (updatedUser.SubscriptionEndDate) updatedUser.SubscriptionEndDate = this.$moment(updatedUser.SubscriptionEndDate).unix();
                    else delete updatedUser['SubscriptionEndDate'];

                    if(updatedUser.StripeCustomer) delete updatedUser['StripeCustomer'];

                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.put(`${config.API_LOCATION}/user/${updatedUser.ID}`, updatedUser, {
                        headers: axiosHeaders
                    });

                    console.log(response.data);

                    //refresh our view of the user row
                    await this.fetchUsers();

                    this.closeUserDialog();
                } catch (err) {
                    if (err.response && err.response.status === 401) {
                        this.$emit('unauthorized');
                    } else {
                        console.error(err);
                        this.snackbar.state = false;
                        this.snackbar.color = 'error';
                        this.snackbar.icon = 'mdi-close';
                        this.snackbar.text = err.response?.data?.message || err.message || 'An unknown error occurred: ' + err;
                        this.snackbar.state = true;
                    }
                }
            },

            openUserDialog(user) {
                this.dialogs.editUser.user = _.clone(user);
                this.dialogs.editUser.origUser = user;
                this.dialogs.editUser.LastLoginFormatted = this.$moment(user.LastLogin).format('M/D/YYYY h:m:sa');
                this.dialogs.editUser.state = true;
            },

            closeUserDialog() {
                this.dialogs.editUser.state = false;
                this.dialogs.editUser.user = null;
            },

            async sendResetPassword(username) {
                try {
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.post(`${config.API_LOCATION}/reset-password`, {
                        identity: username
                    }, {
                        headers: axiosHeaders
                    });

                    if (response.data.success) {
                        this.snackbar.state = false;
                        this.snackbar.color = 'success';
                        this.snackbar.icon = 'mdi-check';
                        this.snackbar.text = 'Password reset email was sent successfully.';
                        this.snackbar.state = true;
                    } else {
                        throw new Error(response.data.message || 'Password reset email failed!');
                    }
                } catch (err) {
                    this.snackbar.state = false;
                    this.snackbar.color = 'error';
                    this.snackbar.icon = 'mdi-close';
                    this.snackbar.text = err.message || 'An unknown error occurred: ' + err;
                    this.snackbar.state = true;
                }
            },

            confirmBanUser(user, ban) {
                const confirm = window.confirm(`Are you sure you want to ${ban ? 'ban' : 'unban'} ${user.Username}?`);
                if (confirm) this.banUser(user, ban);
            },

            async banUser(user, ban) {
                try {
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    //data massaging :\
                    const updatedUser = _.omit(user, ['LastLogin', 'SubscriptionEndDate']);
                    updatedUser.Banned = ban ? 1 : 0;

                    const response = await axios.put(`${config.API_LOCATION}/user/${user.ID}`, updatedUser, {
                        headers: axiosHeaders
                    });

                    if (response.data.success) {
                        //update the users array with the updated user object
                        let foundUser = _.findIndex(this.users, {ID: user.ID});
                        if (foundUser !== -1) this.users[foundUser].Banned = updatedUser.Banned;

                        this.snackbar.state = false;
                        this.snackbar.color = 'success';
                        this.snackbar.icon = 'mdi-check';
                        this.snackbar.text = `User was ${ban ? 'banned' : 'unbanned'} successfully.`;
                        this.snackbar.state = true;
                    } else {
                        throw new Error(response.data.message || `User ${ban ? 'ban' : 'unban'} failed!`);
                    }
                } catch (err) {
                    this.snackbar.state = false;
                    this.snackbar.color = 'error';
                    this.snackbar.icon = 'mdi-close';
                    this.snackbar.text = err.message || 'An unknown error occurred: ' + err;
                    this.snackbar.state = true;
                }
            },

            confirmDeleteUser(user) {
                const confirm = window.confirm(`Are you sure you want to delete ${user.Username}? This cannot be undone.`);
                if (confirm) this.deleteUser(user);
            },

            async deleteUser(user) {
                try {
                    let axiosHeaders = {};
                    if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                    const response = await axios.delete(`${config.API_LOCATION}/user/${user.ID}`, {
                        headers: axiosHeaders
                    });

                    if (response.data.success) {
                        //update the users array with the updated user object
                        let foundUser = _.findIndex(this.users, {ID: user.ID});
                        if (foundUser !== -1) this.users.splice(foundUser, 1);

                        this.snackbar.state = false;
                        this.snackbar.color = 'success';
                        this.snackbar.icon = 'mdi-check';
                        this.snackbar.text = `User was deleted successfully.`;
                        this.snackbar.state = true;
                    } else {
                        throw new Error(response.data.message || `User delete operation failed!`);
                    }
                } catch (err) {
                    this.snackbar.state = false;
                    this.snackbar.color = 'error';
                    this.snackbar.icon = 'mdi-close';
                    this.snackbar.text = err.message || 'An unknown error occurred: ' + err;
                    this.snackbar.state = true;
                }
            }
        },

        mounted() {

        }
    }
</script>
