<template>
    <v-card>
        <v-card-title class="font-weight-light">System Statistics</v-card-title>
        <v-card-text>

            <div class="text-center" v-if="loading">
                <v-progress-circular indeterminate size="100"></v-progress-circular>
            </div>

            <div v-else-if="stats.recent" class="d-flex flex-wrap flex-row justify-space-around">

                <v-card dark color="primary" class="ma-2">
                    <v-card-title class="font-weight-light d-flex flex-column">
                        <span class="display-1 font-weight-regular">{{ stats.recent.users }}</span>
                        <span>Total Users</span>
                    </v-card-title>
                </v-card>

                <v-card dark color="primary" class="ma-2">
                    <v-card-title class="font-weight-light d-flex flex-column">
                        <span class="display-1 font-weight-regular">{{ stats.recent.activeUsers }}</span>
                        <span>Active Users</span>
                    </v-card-title>
                </v-card>

                <v-card dark color="primary" class="ma-2">
                    <v-card-title class="font-weight-light d-flex flex-column">
                        <span class="display-1 font-weight-regular">{{ stats.recent.repeaters }}</span>
                        <span>Total Repeaters</span>
                    </v-card-title>
                </v-card>

                <v-card dark color="primary" class="ma-2">
                    <v-card-title class="font-weight-light d-flex flex-column">
                        <span class="display-1 font-weight-regular">{{ stats.recent.activeRepeaters }}</span>
                        <span>Active Repeaters</span>
                    </v-card-title>
                </v-card>

                <v-card dark color="primary" class="ma-2">
                    <v-card-title class="font-weight-light d-flex flex-column">
                        <span class="display-1 font-weight-regular">{{ stats.recent.states }}</span>
                        <span>States Covered</span>
                    </v-card-title>
                </v-card>

                <v-card dark color="primary" class="ma-2">
                    <v-card-title class="font-weight-light d-flex flex-column">
                        <span class="display-1 font-weight-regular">{{ stats.recent.activeLicenses }}</span>
                        <span>Active Licenses</span>
                    </v-card-title>
                </v-card>

                <v-card dark color="primary" class="ma-2">
                    <v-card-title class="font-weight-light d-flex flex-column">
                        <span class="display-1 font-weight-regular">{{ stats.recent.newLicenses }}</span>
                        <span>New Licenses</span>
                    </v-card-title>
                </v-card>
            </div>

            <div class="d-flex flex-wrap flex-row justify-space-around mt-5">
                <v-card
                    class="text-center mb-5"
                    color="green"
                    dark
                    min-width="400"
                    max-width="600"
                >
                    <v-card-text>
                        <v-sheet color="rgba(0, 0, 0, .12)">
                            <v-sparkline
                                :value="historyActiveUsers"
                                color="rgba(255, 255, 255, .7)"
                                height="100"
                                padding="24"
                                stroke-linecap="round"
                                smooth
                            >
                                <template v-slot:label="item">
                                    {{ parseInt(item.value).toLocaleString('en-US') }}
                                </template>
                            </v-sparkline>
                        </v-sheet>
                    </v-card-text>

                    <v-card-text>
                        <div class="text-h4 font-weight-thin">Users Active in Last Year</div>
                    </v-card-text>

                    <!--                <v-divider></v-divider>-->

                    <!--                <v-card-actions class="justify-center">-->
                    <!--                    <v-btn block text>Go to Report</v-btn>-->
                    <!--                </v-card-actions>-->
                </v-card>

                <v-card
                    class="text-center mb-5"
                    color="green"
                    dark
                    min-width="400"
                    max-width="600"
                >
                    <v-card-text>
                        <v-sheet color="rgba(0, 0, 0, .12)">
                            <v-sparkline
                                :value="historyActiveRepeaters"
                                color="rgba(255, 255, 255, .7)"
                                height="100"
                                padding="24"
                                stroke-linecap="round"
                                smooth
                            >
                                <template v-slot:label="item">
                                    {{ parseInt(item.value).toLocaleString('en-US') }}
                                </template>
                            </v-sparkline>
                        </v-sheet>
                    </v-card-text>

                    <v-card-text>
                        <div class="text-h4 font-weight-thin">Repeaters Active in Last Year</div>
                    </v-card-text>
                </v-card>

                <v-card
                    class="text-center mb-5"
                    color="green"
                    dark
                    min-width="400"
                    max-width="600"
                >
                    <v-card-text>
                        <v-sheet color="rgba(0, 0, 0, .12)">
                            <v-sparkline
                                :value="historyUsers"
                                color="rgba(255, 255, 255, .7)"
                                height="100"
                                padding="24"
                                stroke-linecap="round"
                                smooth
                            >
                                <template v-slot:label="item">
                                    {{ parseInt(item.value).toLocaleString('en-US') }}
                                </template>
                            </v-sparkline>
                        </v-sheet>
                    </v-card-text>

                    <v-card-text>
                        <div class="text-h4 font-weight-thin">Total Users</div>
                    </v-card-text>
                </v-card>

                <v-card
                    class="text-center mb-5"
                    color="green"
                    dark
                    min-width="400"
                    max-width="600"
                >
                    <v-card-text>
                        <v-sheet color="rgba(0, 0, 0, .12)">
                            <v-sparkline
                                :value="historyRepeaters"
                                color="rgba(255, 255, 255, .7)"
                                height="100"
                                padding="24"
                                stroke-linecap="round"
                                smooth
                            >
                                <template v-slot:label="item">
                                    {{ parseInt(item.value).toLocaleString('en-US') }}
                                </template>
                            </v-sparkline>
                        </v-sheet>
                    </v-card-text>

                    <v-card-text>
                        <div class="text-h4 font-weight-thin">Total Repeaters</div>
                    </v-card-text>
                </v-card>

            </div>
        </v-card-text>
    </v-card>
</template>

<style>
    /*.grid-container {*/
    /*    display: grid;*/
    /*    gap: 1em;*/
    /*    grid-template-columns: repeat(auto-fit, minmax(10em, 25em));*/
    /*}*/

    .grid-container {
        display: grid;
        gap: 1em;
    }

    @media (min-width: 35em) {
        .grid-container {
            grid-auto-flow: column;
            grid-auto-columns: 1fr;
        }
    }
</style>

<script>
    import axios from 'axios';
    import config from "@/config";

    export default {
        props: ['user'],

        data: () => ({
            loading: false,
            stats: {
                recent: {
                    activeLicenses: null,
                    activeRepeaters: null,
                    activeUsers: null,
                    newLicenses: null,
                    repeaters: null,
                    networks: null,
                    states: null,
                    users: null
                },
                history: [],
            },
        }),

        computed: {
            historyActiveUsers() {
                return _.map(this.stats.history, o => {
                    return o.activeUsers;
                });
            },

            historyActiveRepeaters() {
                return _.map(this.stats.history, 'activeRepeaters');
            },

            historyUsers() {
                return _.map(this.stats.history, 'users');
            },

            historyRepeaters() {
                return _.map(this.stats.history, 'repeaters');
            },

            historyStates() {
                return _.map(this.stats.history, 'states');
            },

            // historyNetworks() {
            //     return _.map(this.stats.history, 'networks');
            // }
        },

        methods: {
            loadStats() {
                this.loading = true;

                let axiosHeaders = {};
                if (this.user.authToken) axiosHeaders.Authorization = `Bearer ${this.user.authToken}`;

                axios
                    .get(config.API_LOCATION + '/stats', {
                        headers: axiosHeaders
                    })
                    .then(response => {
                        this.stats.recent = {
                            activeLicenses: response.data.recent.activeLicenses.toLocaleString('en-US'),
                            activeRepeaters: response.data.recent.activeRepeaters.toLocaleString('en-US'),
                            activeUsers: response.data.recent.activeUsers.toLocaleString('en-US'),
                            newLicenses: response.data.recent.newLicenses.toLocaleString('en-US'),
                            repeaters: response.data.recent.repeaters.toLocaleString('en-US'),
                            // networks: response.data.recent.networks.toLocaleString('en-US'),
                            states: response.data.recent.states.toLocaleString('en-US'),
                            users: response.data.recent.users.toLocaleString('en-US')
                        };
                        this.stats.history = response.data.history;
                    })
                    .catch(err => {
                        if (err.response && err.response.status === 401) {
                            this.$emit('unauthorized');
                        }
                    })
                    .then(() => {
                        this.loading = false;
                    });
            },
        },

        mounted() {
            this.loadStats();
        },
    }
</script>
