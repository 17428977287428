import { VAlert } from 'vuetify/lib/components/VAlert';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.error.state)?_c(VAlert,{attrs:{"type":"error","colored-border":"","border":"left","dense":""}},[_vm._v(_vm._s(_vm.error.message))]):_vm._e(),_c(VTextField,{staticClass:"mx-5 mt-3",attrs:{"label":"Search","prepend-inner-icon":"mdi-magnify","solo-inverted":"","hide-details":"","clearable":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDataTable,{staticClass:"elevation-2",attrs:{"headers":_vm.headers,"items":_vm.repeaters,"loading":_vm.loading,"server-items-length":_vm.total,"search":_vm.search,"options":_vm.options,"footer-props":{itemsPerPageOptions: [10, 20, 30, 40, 50]},"mobile-breakpoint":0,"sort-by":"Modified","sort-desc":"","must-sort":"","show-expand":"","single-expand":"","item-key":"ID","expanded":_vm.expanded},on:{"update:options":function($event){_vm.options=$event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.rowClick},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('Repeater',{attrs:{"authUser":_vm.user,"repeater":item}})],1)]}},{key:"item.Modified",fn:function(ref){
var item = ref.item;
return [(item.Modified)?_c('span',[_vm._v(_vm._s(_vm.$moment.utc(item.Modified).format('M/D/YYYY'))+" "),_c('small',[_vm._v("("+_vm._s(_vm.$moment.utc(item.Modified).fromNow())+")")])]):_vm._e()]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }